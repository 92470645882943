import {Dispatch, SetStateAction} from 'react'

export type ID = undefined | null | number | string

export type PaginationState = {
  page_number: number
  page_size: 10 | 25 | 50 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
  total?: number
  count?: number
}

export type SortState = {
  sort_field?: string
  sort_order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: {
    exam_id?: ID
    class_id?: ID
    new_class_id?: ID
    section_id?: ID
    student_id?: ID
    teacher_id?: ID
    subject_id?: ID
    academic_year_id?: ID
    role_id?: ID
    status?: boolean | string
    is_view_on_web?: boolean
    date?: string
    language?: string
    created_at?: string
    type?: string
    unitType?: string
    isReturned?: boolean | string
  }
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  entities?: T
  total_count?: number
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page_number: 1,
  page_size: 100,
  sort_field: 'id',
  sort_order: 'desc',
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  state?: QueryState
  response2?: Array<T>
  setResponse2?: (updates: Array<T>) => void
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdToView?: ID
  setItemIdToView: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  isAllSelected2?: boolean
  disabled: boolean
  selected2?: {id: ID; val: string}[]
  onSelect2?: (selectedId: ID, val: string) => void
  onSelectAll2?: (val: string) => void
  clearSelected2?: () => void
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemIdToView: () => {},
  isAllSelected: false,
  isAllSelected2: false,
  disabled: false,
  selected2: [],
  onSelect2: () => {},
  onSelectAll2: () => {},
  clearSelected2: () => {},
}
