/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen008.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/admin'
        title='Adminstartor'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/finance/fin001.svg'
      >
        <AsideMenuItem
          to='/apps/admin/school'
          icon='/media/icons/duotune/general/gen022.svg'
          title='School'
        />
        <AsideMenuItem
          to='/apps/admin/academic-year'
          title='Academic Year'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/apps/admin/role'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Role'
        />
        <AsideMenuItem
          to='/apps/admin/permission'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Permission'
        />
        <AsideMenuItem
          to='/apps/admin/account'
          icon='/media/icons/duotune/general/gen022.svg'
          title='User'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/employee-management'
        title='Employee Management'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com014.svg'
      >
        <AsideMenuItem
          to='/apps/employee-management/employee'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Employee'
        />
        <AsideMenuItem
          to='/apps/employee-management/employee-attendance'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Employee Attendance'
        />
        <AsideMenuItem
          to='/apps/employee-management/department'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Department'
        />
        <AsideMenuItem
          to='/apps/employee-management/designation'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Designation'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/academic'
        title='Academic'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/finance/fin006.svg'
      >
        <AsideMenuItem
          to='/apps/academic/class'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Classes'
        />
        {/* <AsideMenuItem
          to='/apps/academic/class-group'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Class Group'
        /> */}
        <AsideMenuItem
          to='/apps/academic/section'
          title='Section'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/apps/academic/subject'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Subject'
        />
        <AsideMenuItem
          to='/apps/academic/syllabus'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Syllabus'
        />
        <AsideMenuItem
          to='/apps/academic/study-material'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Study material'
        />
        <AsideMenuItem
          to='/apps/academic/class-lecture'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Class lecture'
        />
        <AsideMenuItem
          to='/apps/academic/assignment'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Assignment'
        />
        <AsideMenuItem
          to='/apps/academic/assignment-submission'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Assignment Submission'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/student-management'
        title='Student Management'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        <AsideMenuItem
          to='/apps/student-management/student'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Student'
        />
        <AsideMenuItem
          to='/apps/student-management/student-attendance'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Student Attendance'
        />
        <AsideMenuItem
          to='/apps/student-management/student-type'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Student Type'
        />
        <AsideMenuItem
          to='/apps/student-management/admission'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Online Admission'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/id-cards'
        title='ID Card'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/technology/teh002.svg'
      >
        <AsideMenuItem
          to='/apps/id-cards/student-id-card'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Student ID Card'
        />
        <AsideMenuItem
          to='/apps/id-cards/employee-id-card'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Employee ID Card'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/exam'
        title='Exam'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen055.svg'
      >
        <AsideMenuItem
          to='/apps/exam/exam'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Exam'
        />
        <AsideMenuItem
          to='/apps/exam/schedule'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Schedule'
        />
        <AsideMenuItem
          to='/apps/exam/grade'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Grade'
        />
        <AsideMenuItem
          to='/apps/exam/exam-attendance'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Attendance'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/mark'
        title='Exam Mark'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/ecommerce/ecm011.svg'
      >
        <AsideMenuItem
          to='/apps/mark'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Mark'
        />
        <AsideMenuItem
          to='/apps/marksheet'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Marksheet'
        />
        <AsideMenuItem
          to='/apps/psychomotor/marksheet'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Psychomotor'
        />
        <AsideMenuItem
          to='/apps/examresult'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Exam Term Result'
        />
      
        <AsideMenuItem
          to='/apps/resultcard'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Result Card'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/promotion'
        icon='/media/icons/duotune/arrows/arr056.svg'
        title='Promotion'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
       to='/apps/book'
       title='Books'
      fontIcon='bi-book'
      icon='/media/icons/duotune/general/gen001.svg'
      >
        
        <AsideMenuItem
        to='/apps/book'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Books'
        fontIcon='bi-layers'
      />
        <AsideMenuItem
        to='/apps/book/library_member'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Library Members'
        fontIcon='bi-layers'
      />
         <AsideMenuItem
        to='/apps/bookissued'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Books Issued'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/book/issue'
       icon='/media/icons/duotune/general/gen023.svg'
        title='Issue & Return'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/book/ebook'
        icon='/media/icons/duotune/general/gen022.svg'
        title='E~Books'
        fontIcon='bi-layers'
      />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
      to='/apps/complain'
      title='Complaints'
      fontIcon='bi-layers'
      icon='/media/icons/duotune/general/gen022.svg'
      >
         <AsideMenuItem
        to='/apps/complain'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Complain'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/complain/type'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Complain Type'
        fontIcon='bi-layers'
      />
     
      </AsideMenuItemWithSub>
     

      <AsideMenuItemWithSub
      to='/apps/asset'
      title='Asset Management'
      fontIcon='bi-chat-left'
      icon='/media/icons/duotune/general/gen008.svg'
      >
      <AsideMenuItem
        to='/apps/vendor'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Vendors'
        fontIcon='bi-layers'
      />
       <AsideMenuItem
        to='/apps/asset/asset_store'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Asset Store'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/asset/asset_category'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Asset Category'
        fontIcon='bi-layers'
      />
       <AsideMenuItem
        to='/apps/asset/asset_item'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Asset Items'
         fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/asset/asset_purchase'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Asset Purchase'
        fontIcon='bi-layers'
      />
    
      <AsideMenuItem
        to='/apps/asset/asset_issue'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Asset Issue'
        fontIcon='bi-layers'
      />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
      to='/apps/item'
      title='Items Management'
      fontIcon='bi-chat-left'
      icon='/media/icons/duotune/general/gen002.svg'
      >

      <AsideMenuItem
        to='/apps/item/category'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item Category'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item/issue'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item Issue'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item/product'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item Product'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item/purchase'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item Purchase'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item/sale'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item Sales'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item/supplier'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item Supplier'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item/warehouse'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item Warehouse'
        fontIcon='bi-layers'
      />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/exphead'
        title='Expenditure'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen006.svg'
      >

      <AsideMenuItem
        to='/apps/exphead'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Expenditure Head'
        fontIcon='bi-layers'
      />
         <AsideMenuItem
        to='/apps/expenditure'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Expenditure'
        fontIcon='bi-layers'
      />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
       to='/apps/hostel'
       title='Hostel'
      fontIcon='bi bi-database-fill-add'
      icon='/media/icons/duotune/general/gen001.svg'
      >
 <AsideMenuItem
        to='/apps/hostel'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Hostel'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/hostel/member'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Hostel Member'
        fontIcon='bi-layers'
      />
       <AsideMenuItem
        to='/apps/hostel/room'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Hostel Rooms'
        fontIcon='bi-layers'
      />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/frontend'
        title='Manage Frontend'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen001.svg'
      >
        <AsideMenuItem
          to='/apps/frontend/slider'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Slider'
        />
        <AsideMenuItem
          to='/apps/frontend/about'
          icon='/media/icons/duotune/general/gen022.svg'
          title='About Us'
        />
        <AsideMenuItem
          to='/apps/frontend/news'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Announcement'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/gallery'
        title='Gallery'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen006.svg'
      >
        <AsideMenuItem
          to='/apps/gallery'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Gallery'
        />
        <AsideMenuItem
          to='/apps/gallery/image'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Gallery Images'
        />
      </AsideMenuItemWithSub>

      
      {/* 

      <AsideMenuItem
        to='/apps/admit-card-setting'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Admit card setting'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/activity-log'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Activity log'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/admission'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Admission'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/asset-store'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Asset store'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/award'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Award'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/book'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Book'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/book-issue'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Book issue'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/certificate'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Certificate'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/class'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Class'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/class-group'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Class group'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/complain-type'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Complain type'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/complain'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Complain'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/department'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Department'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/designation'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Designation'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/ebook'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Ebook'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/email-setting'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Email setting'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/email'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Email'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/discount'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Discount'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/enrollment'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Enrollment'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/email-template'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Email template'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/employee'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Employee'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/event'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Event'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/exam-answer'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Exam answer'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/exam-attendance'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Exam attendance'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/exam-instruction'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Exam instruction'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/exam-online-exam'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Exam online exam'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/exam-question'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Exam question'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/exam-result'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Exam result'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/exam-taken-exam'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Exam taken exam'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/expenditure-head'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Expenditure head'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/faq'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Faq'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/exam-to-question'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Exam to question'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/fees-amount'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Fees amount'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/gallery-image'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Gallery image'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/gallery'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Gallery'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/final-result'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Final result'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/gmsms-session'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Gmsms session'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/grade'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Grade'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/holiday'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Holiday'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/hostel-member'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Hostel member'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/global-setting'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Global setting'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/income-head'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Income head'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/invoice'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Invoice'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/id-card-setting'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Id card setting'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item-category'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item category'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/expenditure'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Expenditure'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item-product'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item product'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/invoice-detail'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Invoice detail'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item-sale'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item sale'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item-purchase'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item purchase'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item-stock'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item stock'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item-supplier'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item supplier'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/feedback'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Feedback'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/language'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Language'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/leave-application'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Leave application'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/leave-type'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Leave type'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/library-member'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Library member'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/live-class'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Live class'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/lp-lesson-detail'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Lp lesson detail'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/lp-lesson'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Lp lesson'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/lp-topic-detail'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Lp topic detail'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/lp-topic'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Lp topic'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/mark-email'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Mark email'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/mark'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Mark'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/mark-smse'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Mark smse'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/message-relationship'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Message relationship'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/message'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Message'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/module'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Module'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/news'
        icon='/media/icons/duotune/general/gen022.svg'
        title='News'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/notice'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Notice'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/opening-hour'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Opening hour'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/operation'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Operation'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/payment-setting'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Payment setting'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/page'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Page'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/phone-call-log'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Phone call log'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/postal-receipt'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Postal receipt'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/postal-dispatch'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Postal dispatch'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/purchase'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Purchase'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/rating'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Rating'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/reply'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Reply'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/result'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Result'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/route-stop'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Route stop'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/room'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Room'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/route'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Route'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/routine'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Routine'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/saas-faq'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Saas faq'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/saas-plan'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Saas plan'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/saas-setting'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Saas setting'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/saas-slider'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Saas slider'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/saas-subscription'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Saas subscription'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/salary-grade'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Salary grade'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item-warehouse'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item warehouse'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/section'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Section'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/slider'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Slider'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/hostel'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Hostel'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/sms-setting'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Sms setting'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/ss-balance'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Ss balance'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/ss-candidate'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Ss candidate'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/ss-donor'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Ss donor'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/ss-scholarship'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Ss scholarship'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/student-activity'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Student activity'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/subject'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Subject'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/suggestion'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Suggestion'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/teacher-attendance'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Teacher attendance'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/teacher'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Teacher'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/system-admin'
        icon='/media/icons/duotune/general/gen022.svg'
        title='System admin'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/text-message'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Text message'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/theme'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Theme'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/transaction'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Transaction'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/transport-member'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Transport member'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/todo'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Todo'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/user'
        icon='/media/icons/duotune/general/gen022.svg'
        title='User'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/vehicle'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Vehicle'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/visitor'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Visitor'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/visitor-purpose'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Visitor purpose'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/salary-payment'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Salary payment'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/sms-template'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Sms template'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/item-issue'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Item issue'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
